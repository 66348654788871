.form-app {
    .box {
        //Interaction colors
        $interaction-ok: #207e66;
        $interaction-warning: #ffcf00;
        $interaction-error: #e70000;
        $focus-border-light: #212c8d;
        $focus-border-dark: #57d7ff;

        // Links
        $std-link: #822757;
        $hover-link: #822757;
        $hover-link-bg: #efefef;
        $active-link: #83000c;
        $visited-link: #633263;

        .sr-only {
            position: absolute;
            top: -2000em;
            left: -3000em;
        }

        .datepicker {
            // display: flex;
            position: relative;

            .input-wrapper {
                display: flex;
            }

            .group {
                display: inline-block;
                position: relative;
                width: 13em;

                button {
                    width: 40px;
                    height: 40px;
                }
            }

            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                margin: 0;
                position: relative;
                height: 48px;

                &.has-error {
                    &:hover {
                        z-index: 0;
                    }
                }

                &:hover,
                &:focus {
                    z-index: 99999999;
                }
            }

            .picker-button {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                button.icon {
                    width: 48px;
                    display: inline-block;
                    position: relative;
                    cursor: pointer;
                    margin-bottom: 0px;
                    background-color: #FFF;
                    border: 1px solid #868686;
                    height: 48px;
                    margin-left: -1px;
                    border-radius: 4px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;

                    &.has-error {
                        outline: 2px solid $ot-red;
                    }

                    &:hover {
                        outline: 2px solid #868686;
                        outline-offset: -1px;
                    }

                    &:focus {
                        outline: 2px solid #004f6f;
                        outline-offset: -1px;
                    }

                    &:before {
                        margin-left: -2px;
                        font-family: ogt-icons !important;
                        speak: never;
                        font-style: normal;
                        font-weight: 400;
                        font-variant: normal;
                        text-transform: none;
                        padding: 4px;
                        color: $ot-red;
                        content: $ogt-date-16;
                        font-size: 26px;
                    }
                }
            }

            .desc {
                position: absolute;
                left: 0;
                top: 2em;
            }

            .fa-calendar-alt {
                color: #{"hsl(216deg 89% 51%)"};
            }

            // button.icon:focus {
            // }
            // input:focus {
            // }
        }

        .datepicker-dialog {
            display: block;
            position: absolute;
            width: 320px;
            clear: both;
            border: 1px solid #8c8c8c;
            margin-top: -22px;
            border-radius: 5px;
            padding: 0;
            background-color: #ffffff;
            z-index: 2;

            @media screen and (max-width: 480px) {
                left: 50%;
                transform: translateX(-50%);
            }

            .table-wrap {
                // width: 100%;
                // overflow: auto;
            }

            .header {
                cursor: default;
                background-color: #ffffff;
                padding: 1em;
                font-weight: bold;
                text-transform: uppercase;
                color: #333333;
                display: flex;
                justify-content: space-around;
                align-items: baseline;
            }

            h2 {
                margin: 0;
                padding: 0;
                display: inline-block;
                font-size: 18px !important;
                line-height: 25px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-weight: bold;
                border: none;
            }

            .dates {
                width: 320px;
            }



            .prev-month {

                &:before {
                    content: $ogt-chevron-left-16;
                }
            }

            .next-month {

                &:before {
                    content: $ogt-chevron-right-16;
                }
            }

            .prev-month,
            .next-month {
                font-family: ogt-icons !important;
                speak: never;
                font-style: normal;
                font-weight: 600;
                font-variant: normal;
                text-transform: none;
                padding: 4px;
                color: #333333;
                font-size: 0.75rem;
                margin: 0;
                width: 24px;
                height: 24px;

                &:focus {
                    color: #333;
                    outline: 3px solid $ot-red !important;
                    outline-offset: 2px;
                }

                &:hover {
                    outline: 3px solid $ot-red !important;
                    outline-offset: 2px;
                    cursor: pointer;
                }
            }

            .dialog-ok-cancel-group {
                text-align: right;
                margin-top: 1em;
                margin-bottom: 1em;
                margin-right: 1em;
                margin-left: 1em;
            }

            .dialog-button:focus {}

            .dialog-button:hover {}

            .fa-calendar-alt {
                color: #{"hsl(216deg 89% 51%)"};
            }

            .month-year {
                display: inline-block;
                width: 12em;
                text-align: center;
            }

            table.dates {
                padding-left: 1em;
                padding-right: 1em;
                padding-top: 1em;
                border: none;
                border-collapse: separate;

                thead {
                    tr {
                        th {
                            padding: 0;
                            font-size: 14px;
                        }
                    }
                }

                td {
                    &:hover {
                        cursor: pointer;
                        padding: 0;
                        border: 3px solid $ot-red;
                        color: #333;
                    }
                }

                th,
                td {
                    text-align: center;
                    background: white;
                    color: black;
                    border: none;

                    &.disabled {
                        padding: 2px;
                        border: none;
                        height: 41px;
                        width: 41px;
                        color: #adadad;
                    }

                    &:focus {
                        padding: 1px;
                        //border: 2px solid #{"rgb(100 100 100)"};
                        outline: 0;
                    }

                    &:not(.disabled):hover {
                        //padding: 2px;
                        //border: 1px solid #{"rgb(100 100 100)"};
                    }

                    &[aria-selected] {
                        padding: 1px;
                        border: 2px dotted #{"rgb(100 100 100)"};
                    }

                    &[aria-selected]:focus {
                        padding: 1px;
                        // border: 2px solid #{"rgb(100 100 100)"};
                    }

                    &[tabindex="0"] {
                        background-color: $ot-red;
                        color: #ffffff;
                        border: 2px solid #004F6F;
                    }
                }

                tr {
                    border: 1px solid black;
                    background: none;
                }

                td {
                    padding: 3px;
                    margin: 0;
                    line-height: inherit;
                    height: 40px;
                    width: 40px;
                    border-radius: 40px;
                    font-size: 14px;
                    background: #ffffff;
                }
            }

            .dialog-message {
                padding: 0.5em;
                text-align: center;
                font-size: 1rem;
                background: #f2f0ef;
                color: black;
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
            }
        }
    }
}