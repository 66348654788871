@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./components/header";
@import "./components/footer";
@import "./variables/variables";
@import "./common/icons";
@import "./common/fontface";
@import "./common/breakpoints";
@import "./components/box";
@import "./components/forms";
@import "./components/buttons";
@import "./components/progressbar";
@import "./components/fileupload";
@import "./components/_summary";
@import "./components/accordion";
@import "./components/datepicker";
@import "./globals";
